/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchConnectLoginHtmlCodeStatus } from '../../redux/actions/specificPanelActions';
import { Button, Fab, useMediaQuery, useTheme } from '@mui/material';
import { EmptyState } from '@brightlayer-ui/react-components';
import { DeviceWithKeypad } from '@brightlayer-ui/icons-mui';
import RefreshIcon from '@mui/icons-material/Refresh';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';
import CropFree from '@mui/icons-material/CropFree';


type Props = {
    value:string
    showAppBar:boolean
    setShowAppBar:(flag:boolean)=>void
}

export default function IFrameTab({value,setShowAppBar,showAppBar}:Props): JSX.Element {
    const dispatch = useTypedDispatch()
    const { drawerState } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { currentUser } = useSelector((state: any) => state.userReducer);
    //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
    const { specificPanel, htmlCodeStatus } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const [state, setState] = React.useState(true)
    const theme = useTheme();    
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    
    const fetching = async() => {
        await dispatch(fetchConnectLoginHtmlCodeStatus(specificPanel?.panelId))
    }

    const handleTryAgain = () => {
        fetching()
    }

    React.useEffect(() => {
        if (state) {
            fetching()
            setState(false)
        }
    }, [])

    const handleAppBarChange=()=>{        
        setShowAppBar(!showAppBar)
    }
    return (
        <>
                          
            {currentUser?.userType !== 'OFFICE_STAFF' && htmlCodeStatus !== 200 ? (
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'80vh'}}>
                    <EmptyState
                        icon={<DeviceWithKeypad fontSize="inherit" />}
                        title={undefined}
                        description={'Unable to connect to the panel'}
                        actions={
                        <Button variant={'contained'} color={'primary'} onClick={handleTryAgain} startIcon={<RefreshIcon />}>
                            {'Try again'}
                        </Button>} 
                        nonce={undefined} onResize={undefined} onResizeCapture={undefined}                    
                        />
                </div>
            ) : (
                <>
                 {md && htmlCodeStatus === 200 && <>
                    <Fab onClick={handleAppBarChange} color="primary" sx={{ position: 'fixed', bottom: '4%', left: '5%' }}>
                      <CropFree />
                    </Fab>
                 </>}
                <iframe
                    id="connect"
                    onLoad={() => setState(true)}
                    style={{                       
                        width:drawerState === 'open' ? '77vw' : md === true ? '99vw': '97vw',                               
                        height:md ?  (showAppBar ===false ? '98vh' :'86vh') :'83vh',                                                            
                        border: 'none',
                        overflow: 'hidden',
                        marginTop:md ? '-6%':'-1%',
                        marginLeft: sm ? '-6%' : md ? '-5%' : '-1%',
                    }}
                    src={
                        htmlCodeStatus === 200
                            ? value === 'connect'
                                ? `${REACT_APP_BASE_URL}/m2m-eaton-web/iframe/panelid-${specificPanel?.panelId}/expart/expart/login.cgi`
                                : `${REACT_APP_BASE_URL}/m2m-eaton-web/iframe/panelid-${specificPanel?.panelId}/expart/expart/login.cgi?vkp=1`
                            : ''
                    }
                />
                </>
            )}
        </>
    );
}
