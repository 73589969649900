/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/array-type */
import { Box, Button, Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { InfoListItem, EmptyState } from '@brightlayer-ui/react-components';
import { EstateProps, EstateArrayProps } from './panelEstateTypes';
import { EstateContext } from '../ListAndGridView/PanelsView';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { SEARCH_PARAM_ESTATES } from '../../constants/index';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EstateMenuList from '../EstateMenuButton/EstateMenuList';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setEstate } from '../../redux/actions/esateActions';
import { useTranslation } from 'react-i18next';
import secureLocalStorage  from  "react-secure-storage";
import FolderIcon from '@mui/icons-material/Folder';

type Props = {
    path?:string
}

export default function ListOfEstates({path}:Props): JSX.Element {
    let estates: EstateArrayProps = React.useContext(EstateContext);
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const searchParam = secureLocalStorage.getItem(SEARCH_PARAM_ESTATES);
    const role = secureLocalStorage.getItem('role');
    const { currentUser } = useSelector((state: any) => state.userReducer);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sortType, setSortType] = React.useState<string>('names');
    const [estateNamesOrder, setEstateNamesOrder] = React.useState<boolean>(false);
    const [estatePanelsOrder, setEstatePanelsOrder] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [estateId, setEstateId] = React.useState<number | undefined>(undefined);
    const [estateName, setEstateName] = React.useState<string>('');
    const [page, setPage] = React.useState<number>(0);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleEstateNamesOrder = () => {
        setSortType('names');
        setEstateNamesOrder(!estateNamesOrder);
    };
    const handleEstateClick = (estate:EstateProps) => {
        secureLocalStorage.setItem('estate',estate)
        secureLocalStorage.setItem('pathArray',[estate])
        dispatch(setEstate(estate))
        navigate(`/panel/estate-group/${estate.ID}`)
    }
    const handleNoOfPanelsOrder = () => {
        setEstatePanelsOrder(!estatePanelsOrder);
    };

    const handleEstateMenuClick = (event: React.MouseEvent<HTMLButtonElement>,id:number | undefined,name:string): any => {
        setAnchorEl(event.currentTarget);
        setEstateId(id)
        setEstateName(name)
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setPage(0)
    }, [searchParam?.toString().length])

    try {
        if (sortType === 'names') {
            if (!estateNamesOrder) {
                const compareEstateNames = (a: EstateProps, b: EstateProps): any => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                };

                estates.length ? estates.sort(compareEstateNames) : (estates = []);
            }
            if (estateNamesOrder) {
                const compareEstateNames = (a: EstateProps, b: EstateProps): any => {
                    if (a.name < b.name) {
                        return 1;
                    }
                    if (a.name > b.name) {
                        return -1;
                    }
                    return 0;
                };

                estates.length ? estates.sort(compareEstateNames) : (estates = []);
            }
        }
    } catch (exception) {
        console.log(`Exception in panels/estates sorting: ${JSON.stringify(exception)}`);
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    return (
        <>
            {md ?
                <Table sx={{ border: '1px solid rgba(66, 78, 84, 0.12)' }}>
                    <TableBody>
                        {estates.length !== 0 &&
                            estates?.slice((page * rowsPerPage),(page * rowsPerPage) + rowsPerPage).map((estate: EstateProps, key: number) => (
                                
                                    <TableRow key={key} sx={{ backgroundColor: '#ffff' }}>
                                        <TableCell padding='normal' align="left">
                                            <Typography variant="body2">{estate.name.slice(0,20)}</Typography>
                                        </TableCell>

                                        <TableCell padding='normal' align="left">
                                            
                                        </TableCell>
                                        <TableCell align="right" padding="none">
                                            {
                                                <Button onClick={() => handleEstateClick(estate)}>
                                                    <ChevronRightIcon color="action" />
                                                </Button>
                                            }
                                        </TableCell>
                                    </TableRow>
                              
                            ))}                           
                    </TableBody>
                </Table>

                :
                <Table>
                    <TableBody>
                        {estates.length !== 0 &&
                            estates?.slice((page * rowsPerPage),(page * rowsPerPage) + rowsPerPage).map((estate: EstateProps, key: number) => (
                              
                                    <TableRow key={key} sx={{ backgroundColor: '#ffff' }}>
                                        <TableCell align="left" padding="none">
                                            <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                icon={<FolderIcon/>}
                                                title={
                                                    <div style={{display:"flex" ,flexDirection:"row"}}>
                                                      <Typography variant="body1">{estate.name}</Typography>
                                                      &ensp;
                                                      <Typography sx={{fontWeight:300}}> {role === 'ADMIN' ?'':t('lbl_estates')}</Typography>
                                                    </div>
                                                }
                                                
                                            />
                                        </TableCell>

                                        <TableCell padding="normal" style={{textAlign:'center'}}>
                                            
                                        </TableCell>
                                        <TableCell padding="normal" style={{textAlign:'center'}}>
                                            
                                        </TableCell>
                                        <TableCell align="right" padding="none">
                                        <div style={{ display: 'flex', flexDirection: 'row', float: 'right',paddingRight:'16px' }}>                                           
                                            <IconButton onClick={() => handleEstateClick(estate)}>
                                                <ChevronRightIcon color="action" />
                                            </IconButton>
                                        </div>
                                        <EstateMenuList 
                                            path={path}
                                            screen='overview'
                                            subEstates={undefined}
                                            estatePage={false} 
                                            anchorEl={anchorEl}
                                            open={open}
                                            setAnchorEl={setAnchorEl}
                                            estateId = {estateId}
                                            estateName={estateName}
                                             />
                                        </TableCell>
                                    </TableRow>
                                
                            ))}                           
                    </TableBody>
                </Table>
            }
            {estates.length === 0 && searchParam !== '' && (
                <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
                    <div style={{ flex: '1 1 0px' }}>
                        <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                            icon={<ManageSearchIcon fontSize={'inherit'} />}
                            title={`${t('msg_noSearch_results')} "${searchParam}"...`}
                        />
                    </div>
                </div>
            )}
        </>
    );
}