/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import {
    AuthContextProvider,
    ContactSupportScreen,
    ReactRouterAuthGuard,
    ReactRouterGuestGuard,
    ForgotPasswordScreen,
    RegistrationContextProvider,
    ResetPasswordScreen,
    RegistrationWorkflow,
} from '@brightlayer-ui/react-auth-workflow';
import { useApp } from '../contexts/AppContextProvider';
import { useNavigate } from 'react-router';
import { ProjectAuthUIActions } from '../actions/AuthUIActions';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Login } from '../screens/Login';
import { ProjectRegistrationUIActions } from '../actions/RegistrationUIActions';
import { routes } from './Routing';
//import { Panels } from '../pages';
import i18nAppInstance from '../i18n';
import i18n from 'i18next';
import { ChangePassword } from '../components/ChangePassword';
import { ExampleHome } from '../screens/ExampleHome';
import RegistrationScreen from '../screens/CreateAccountScreens/RegistrationScreens';
import RegisterConfirmation from '../screens/CreateAccountScreens/RegisterConfirmation';
import { Typography } from '@mui/material';
import ActivationRequired from '../screens/CreateAccountScreens/ActivationRequired';
import MessageSent from '../screens/CreateAccountScreens/MessageSentScreen';
import secureLocalStorage from "react-secure-storage";
import { PanelDetailTabs } from '../pages';
import { PanelAccess } from '../pages/SpecificPanelAccess/PanelAccess';
import { INSTALLER_MENU, ADMIN_MENU, ENGINEER_MENU, MANAGER_MENU, OFFICESTAFF_MENU, ENDUSER_MENU } from '../router/routes';
import { ROLES } from '../entities/Constants';
import { Estate } from '../components/EstatePage/Estate';
import { InstallerDetailsTabs } from '../components/Installers/InstallerDetailsTabs/InstallerDetailsTabs';
import PasswordReset from '../screens/PasswordReset';
import PasswordResetSuccess from '../screens/PasswordReserSuccess';
import DeleteUserAccount from '../screens/DeleteUserAccount';
import DeleteAccountSuccess from '../screens/DeleteAccountSuccess';
import DeleteUserAccountConfirmation from '../screens/DeleteUserAccountConfirmation';
import LicensePage from '../components/SystemConfiguration/LicenseCertificatePage/LicensePage';
import TermandConditionPage from '../components/SystemConfiguration/LicenseCertificatePage/TermandConditionPage';
import UserRegisterEmailPage from '../components/SystemConfiguration/Mail/EmailSettingsPage/UserRegisterEmailPage';
import StateChangeEmailPage from '../components/SystemConfiguration/Mail/EmailSettingsPage/StateChangeEmailPage';
import ForgetLoginSubject from '../components/SystemConfiguration/Mail/EmailSettingsPage/ForgetLoginSubject';
import ForgotLoginEmail from '../components/SystemConfiguration/Mail/EmailSettingsPage/ForgotLoginEmail';
import AppUserCreationEmail from '../components/SystemConfiguration/Mail/EmailSettingsPage/AppUserCreationEmail';
import ForgotPassword from '../components/SystemConfiguration/Mail/EmailSettingsPage/ForgotPassword';
import MaintenanceSubject from '../components/SystemConfiguration/Mail/EmailSettingsPage/MaintenanceSubject';
import MaintenanceText from '../components/SystemConfiguration/Mail/EmailSettingsPage/MaintenanceText';
import RaisedAlertEvent from '../components/SystemConfiguration/Mail/EmailSettingsPage/RaisedAlertEvent';
import SelfTestFailure from '../components/SystemConfiguration/Mail/EmailSettingsPage/SelfTestFailure';
import SIAEmailSubject from '../components/SystemConfiguration/Mail/EmailSettingsPage/SIAEmailSubject';
import SIAMessageText from '../components/SystemConfiguration/Mail/EmailSettingsPage/SIAMessageText';

export const AppRouter: React.FC = () => {
    const navigate = useNavigate();
    const app = useApp();
    const { email, rememberMe } = app.loginData;
    const locale = localStorage.getItem('locale') || 'en';

    if (locale !== '') {
        i18n.changeLanguage(locale).catch((e) => console.log(e))
        app.language = locale;
    }

    return (
        <Routes>
            {/* AUTH ROUTES */}
            <Route
                element={
                    <AuthContextProvider
                        actions={ProjectAuthUIActions(app, navigate)}
                        language={app.language}
                        navigate={navigate}
                        routeConfig={routes}
                        i18n={i18nAppInstance}
                        rememberMeDetails={{ email: rememberMe ? email : '', rememberMe: rememberMe }}
                    >
                        <Outlet />
                    </AuthContextProvider>
                }
            >
                <Route
                    path={'/login'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/'}>
                            <Login />
                        </ReactRouterGuestGuard>
                    }
                />
                <Route
                    path={'/forgot-password'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/'}>
                            <ForgotPasswordScreen
                                errorDisplayConfig={{
                                    dialogConfig: {
                                        title: 'Notification',
                                        dismissLabel: 'Okay',
                                    },
                                }}
                            />
                        </ReactRouterGuestGuard>
                    }
                />
                <Route
                    path={'/contact-support'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/'}>
                            <ContactSupportScreen
                                emailSupportContent={(): any => (
                                    <Typography>
                                        Installers questions, feedback, or support please email us at&nbsp;
                                        <Typography
                                            variant="button"
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: 15,
                                                textTransform: 'none',
                                                textDecoration: 'none',
                                                color: 'primary.main',
                                                '&:visited': {
                                                    color: 'inherit',
                                                },
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                },
                                            }}
                                            // sx={{ ...LinkStyles, fontSize: 'inherit' }}
                                            component="a"
                                            href={`mailto:${`admin@eatonsecureconnect.com` ?? ''}`}
                                        >
                                            admin@eatonsecureconnect.com
                                        </Typography>
                                        &nbsp;or call at&nbsp;
                                        <Typography
                                            variant="button"
                                            sx={{
                                                fontWeight: 600,
                                                textTransform: 'none',
                                                textDecoration: 'none',
                                                color: 'primary.main',
                                                '&:visited': {
                                                    color: 'inherit',
                                                },
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                },
                                            }}
                                            // sx={{ ...LinkStyles, fontSize: 'inherit' }}
                                            component="a"
                                            href={`tel:${`01594 541978` ?? ''}`}
                                        >
                                            01594 541978
                                        </Typography>
                                    </Typography>
                                )}
                                phoneSupportContent={(): any => <Typography></Typography>}
                            />
                        </ReactRouterGuestGuard>
                    }
                />
                <Route
                    path={'/m2m-web-admin/reset-password/:user/:login/:hash'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/'}>
                            <PasswordReset />
                        </ReactRouterGuestGuard>
                    }
                />
                <Route
                    path={'/reset-password-success'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/'}>
                            <PasswordResetSuccess />
                        </ReactRouterGuestGuard>
                    }
                />
                <Route
                    path={'/delete-user-account'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/'}>
                            <DeleteUserAccount />
                        </ReactRouterGuestGuard>
                    }
                />
                <Route
                    path={'/m2m-web-admin/delete-user-account-confirmation/:user/:login/:hash'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/'}>
                            <DeleteUserAccountConfirmation />
                        </ReactRouterGuestGuard>
                    }
                />
                <Route
                    path={'/delete-account-success'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/'}>
                            <DeleteAccountSuccess />
                        </ReactRouterGuestGuard>
                    }
                />
                
                {/* USER APPLICATION ROUTES */}
                <Route
                    element={
                        <>
                            <Outlet />
                            {app.showChangePasswordDialog && <ChangePassword />}
                        </>
                    }
                >
                    <Route
                        path={'/'}
                        element={
                            <ReactRouterAuthGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/login'}>
                                <ExampleHome />
                            </ReactRouterAuthGuard>
                        }
                    >
                        {
                            <>
                                {secureLocalStorage.getItem('role') === ROLES.INSTALLER &&
                                    INSTALLER_MENU.map((page) => {
                                        const RouteElement = page.component;
                                        return (
                                            <Route
                                                key={`route_${page.route}`}
                                                path={`${page.route}`}
                                                element={<RouteElement />}
                                            />
                                        );
                                    })}

                                {secureLocalStorage.getItem('role') === ROLES.MANAGER &&
                                    MANAGER_MENU.map((page) => {
                                        const RouteElement = page.component;
                                        return (
                                            <Route
                                                key={`route_${page.route}`}
                                                path={`${page.route}`}
                                                element={<RouteElement />}
                                            />
                                        );
                                    })}

                                {secureLocalStorage.getItem('role') === ROLES.ENGINEER &&
                                    ENGINEER_MENU.map((page) => {
                                        const RouteElement = page.component;
                                        return (
                                            <Route
                                                key={`route_${page.route}`}
                                                path={`${page.route}`}
                                                element={<RouteElement />}
                                            />
                                        );
                                    })}

                                {secureLocalStorage.getItem('role') === ROLES.ENDUSER &&
                                    ENDUSER_MENU.map((page) => {
                                        const RouteElement = page.component;
                                        return (
                                            <Route
                                                key={`route_${page.route}`}
                                                path={`${page.route}`}
                                                element={<RouteElement />}
                                            />
                                        );
                                    })}

                                {secureLocalStorage.getItem('role') === ROLES.OFFICESTAFF &&
                                    OFFICESTAFF_MENU.map((page) => {
                                        const RouteElement = page.component;
                                        return (
                                            <Route
                                                key={`route_${page.route}`}
                                                path={`${page.route}`}
                                                element={<RouteElement />}
                                            />
                                        );
                                    })}

                                {secureLocalStorage.getItem('role') === ROLES.ADMIN &&
                                    ADMIN_MENU.map((page) => {
                                        const RouteElement = page.component;
                                        return (
                                            <Route
                                                key={`route_${page.route}`}
                                                path={`${page.route}`}
                                                element={<RouteElement />}
                                            />
                                        );
                                    })}

                                <Route key={`route_panel/:id`} path={'panel/:id'} element={<PanelDetailTabs />} />
                                <Route
                                    key={`route_panel/:id/panel-access`}
                                    path={'panel/:id/panel-access'}
                                    element={<PanelAccess />}
                                />
                                <Route
                                    key={`route_panel/estate-group/:id`}
                                    path={'panel/estate-group/:id'}
                                    element={<Estate />}
                                />
                                <Route
                                    path={'/system_configuration/license-page'}
                                    element={<LicensePage />}
                                />

                                <Route
                                    path={'/system_configuration/term-and-condition-page'}
                                    element={<TermandConditionPage />}
                                />
                                <Route
                                    path={'/system_configuration/user-register-email-page'}
                                    element={<UserRegisterEmailPage />}
                                />
                                <Route
                                    path={'/system_configuration/state-change-email-page'}
                                    element={<StateChangeEmailPage />}
                                />
                                <Route
                                    path={'/system_configuration/forgot-login-subject'}
                                    element={<ForgetLoginSubject />}
                                />
                                <Route
                                    path={'/system_configuration/forgot-email-page'}
                                    element={<ForgotLoginEmail />}
                                />
                                <Route
                                    path={'/system_configuration/app-user-creation-email-page'}
                                    element={<AppUserCreationEmail />}
                                />
                                <Route
                                    path={'/system_configuration/forgot-password-email-page'}
                                    element={<ForgotPassword />}
                                />
                                <Route
                                    path={'/system_configuration/maintenance-subject-page'}
                                    element={<MaintenanceSubject />}
                                />
                                <Route
                                    path={'/system_configuration/maintenance-text-page'}
                                    element={<MaintenanceText />}
                                />
                                <Route
                                    path={'/system_configuration/raised-event-email-page'}
                                    element={<RaisedAlertEvent />}
                                />
                                <Route
                                    path={'/system_configuration/self-test-email-page'}
                                    element={<SelfTestFailure />}
                                />
                                <Route
                                    path={'/system_configuration/sia-email-subject-page'}
                                    element={<SIAEmailSubject />}
                                />
                                <Route
                                    path={'/system_configuration/sia-message-subject-page'}
                                    element={<SIAMessageText />}
                                />
                                {secureLocalStorage.getItem('role') === 'ADMIN' && (
                                    <Route
                                        key={`route_panel/installers/installer-details/:id`}
                                        path={'installers/installer-details/:id'}
                                        element={<InstallerDetailsTabs />}
                                    />
                                )}
                                <Route path={'*'} element={<Navigate to={'/'} />} />
                            </>
                        }
                    </Route>
                    <Route path={'*'} element={<Navigate to={'/'} replace />} />
                    <Route
                        path={'/register-confirmation'}
                        element={
                            <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/login'}>
                                <RegisterConfirmation />
                            </ReactRouterGuestGuard>
                        }
                    />
                    <Route
                        path={'/activation-required'}
                        element={
                            <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/login'}>
                                <ActivationRequired />
                            </ReactRouterGuestGuard>
                        }
                    />
                    <Route
                        path={'/message-sent'}
                        element={
                            <ReactRouterGuestGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/login'}>
                                <MessageSent />
                            </ReactRouterGuestGuard>
                        }
                    />
                </Route>
                <Route
                    path={'*'}
                    element={
                        <ReactRouterAuthGuard isAuthenticated={app.isAuthenticated} fallBackUrl={'/login'}>
                            <Navigate to={'/login'} />
                        </ReactRouterAuthGuard>
                    }
                />
            </Route>
            {/* REGISTRATION ROUTES */}
            <Route
                element={
                    <RegistrationContextProvider
                        language={app.language}
                        routeConfig={routes}
                        navigate={navigate}
                        actions={ProjectRegistrationUIActions()}
                        i18n={i18nAppInstance}
                    >
                        <RegistrationScreen />
                        {/* <Outlet /> */}
                    </RegistrationContextProvider>
                }
            >
                <Route path={'/self-registration'} element={<RegistrationWorkflow />} />
                <Route path={'/register-by-invite'} element={<RegistrationWorkflow isInviteRegistration />} />
            </Route>
        </Routes>
    );
};