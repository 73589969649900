/* eslint-disable prefer-template */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-shadow */

import { AuthUIActions } from '@brightlayer-ui/react-auth-workflow';
import { AppContextType } from '../contexts/AppContextProvider';
import { LocalStorage } from '../store/local-storage';
import { sha256 } from 'js-sha256';
import { STATUS_CODES } from '../entities/RequestStatus';
import { REACT_APP_BASE_URL } from '../entities/Endpoints';
import i18n from 'i18next';
import { ROLES } from '../entities/Constants';
import  secureLocalStorage  from  "react-secure-storage";
import { NavigateFunction } from 'react-router';

const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

function getRandomInt(max: number): number {
    return Math.floor(Math.random() * Math.floor(max));
}

function isRandomFailure(): boolean {
    const randomResponseNumber = getRandomInt(100);
    return false; // randomResponseNumber < 10;
}


type AuthUIActionsWithApp = (appHelper: AppContextType, navigate: NavigateFunction) => AuthUIActions;

async function performLogin(email: string, password: string, navigate: NavigateFunction) {
    await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/nonce/${email}`)
        .then((res) => res.text())
        .then(async (nonce) => {
            // eslint-disable-next-line no-console
            console.log(`nonce is : ${nonce}`);
            const body = { username: email, password: password, forceLogout: true };
            await fetch(`${REACT_APP_BASE_URL}/m2m-auth/rest/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'access_token': sha256(`${email}:${nonce}`),
                },
                credentials: 'include',
                body: JSON.stringify(body)
            })
                .then(async (res) => {
                    // eslint-disable-next-line no-console
                    if (res.status !== STATUS_CODES.HTTP_OK) {
                        if(res.status === 403){
                            const txt = await res.text()
                            if(txt === 'An email has been sent to your email, please click the link in that message to confirm your email address.'){
                                sessionStorage.setItem('mailId',email)
                                navigate('/activation-required')
                            }
                            else
                            throw new Error(getErrorText(res.status));
                        }
                        else
                        throw new Error(getErrorText(res.status));
                    }
                    
                    sessionStorage.setItem('isClosed','false')

                    await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/auth/currentuser`, {
                        method: 'GET',
                        credentials: 'include',
                    }).then((resource) => resource.json())
                        .then((data) => {
                            secureLocalStorage.setItem('estate',data)
                            secureLocalStorage.setItem('currentUser',data)
                            if (data.userType === ROLES.INSTALLER) {
                                secureLocalStorage.setItem('role', 'INSTALLER');
                            } else if(data.userType === ROLES.MANAGER) {
                                secureLocalStorage.setItem('role', 'MANAGER');
                            } else if(data.userType === ROLES.ENGINEER) {
                                secureLocalStorage.setItem('role', 'ENGINEER');
                            } else if(data.userType === ROLES.ADMIN) {
                                secureLocalStorage.setItem('role', 'ADMIN');
                            } else if(data.userType === ROLES.OFFICESTAFF) {
                                secureLocalStorage.setItem('role', 'OFFICE_STAFF');
                            } else {
                                secureLocalStorage.setItem('role', 'MOBILE_APPLICATION_USER');
                            }
                            const locale = data.locale.slice(0, -3);
                            i18n.changeLanguage(locale);
                            localStorage.setItem('locale', locale)
                            secureLocalStorage.setItem('firstName', data.firstName)
                            secureLocalStorage.setItem('lastName', data.lastName)
                            secureLocalStorage.setItem('email', data.login)
                        })
                    })
                });
}

async function forgotPasswordApi(email:string) {
    await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/forgotPassword/sendLink/${email}`)
    .then((res) => {
        if(res.status === 405){
            throw new Error(i18n.t('lbl_pwdResetErrMsg'));
        }
        else
            throw new Error(i18n.t('lbl_forgotPwd')+' "'+email+'".');
    })
}

const getErrorText = (code: number): string => {
    let errorText = '';

    switch (code) {
        case STATUS_CODES.HTTP_ERROR_INVALID_REQUEST:
            errorText = 'MESSAGES.REQUEST_ERROR';
            break;
        case STATUS_CODES.HTTP_ERROR_RESTRICTED_USER_ACCESS:
            errorText = 'bluiAuth:LOGIN.INVALID_CREDENTIALS';
            break;
        case STATUS_CODES.HTTP_ERROR_UNAUTHORIZED_USER:
            errorText = 'MESSAGES.REQUEST_ERROR';
            break;
        case STATUS_CODES.HTTP_ERROR_NOT_FOUND:
            errorText = 'MESSAGES.FAILURE';
            break;
        case STATUS_CODES.HTTP_INTERNAL_SERVER_ERROR:
            errorText = 'MESSAGES.REQUEST_ERROR';
            break;
        default:
            errorText = 'Something went wrong. Please try again.';
            break;
    }
    return errorText;
};

/**
 * Example implementation of [[AuthUIActions]] to start with during development.
 *
 * Authentication Actions to be performed based on the user's UI actions. The application will create
 * appropriate actions (often api calls, local network storage, credential updates, etc) and update
 * the global security state based on the actionable needs of the user.
 */
export const ProjectAuthUIActions: AuthUIActionsWithApp = (appHelper, navigate) => ({
    
    /**
     * Initialize the application security state. This will involve reading any local storage,
     * validating existing credentials (token expiration, for example). At the end of validation,
     * the [[SecurityContextActions]] should be called with either:
     * [[onUserAuthenticated]] (which will present the application), or
     * [[onUserNotAuthenticated]] (which will present the Auth UI).
     *
     * Note: Until this method returns, the applications Splash screen will be presented.
     *
     * @returns Should always resolve. Never throw.
     */
    initiateSecurity: async (): Promise<void> => {
        let authData;

        try {
            await sleep(2000);
            authData = await LocalStorage.readAuthData();
        } catch (e) {
            // Restoring token failed
        }

        // After restoring token, we may need to validate it in production apps
        // This will switch to the App screen or Auth screen and this loading
        // screen will be unmounted and thrown away.
        // securityHelper.onUserAuthenticated()
        if (authData?.email !== undefined) {
            appHelper.onUserAuthenticated({
                email: authData?.email,
                userId: authData.userId ?? '',
                rememberMe: authData?.rememberMeData.rememberMe,
            });
        } else {
            const rememberMeEmail = authData?.rememberMeData.rememberMe ? authData?.rememberMeData.user : undefined;
            appHelper.onUserNotAuthenticated(false, rememberMeEmail);
        }
    },
    /**
     * The user wants to log into the application. Perform a login with the user's credentials.
     * The application should provide the user's email and password to the authentication server.
     *
     * In the case of valid credentials, the applications code should store the returned data
     * (such as token, user information, etc.). Then the [[onUserAuthenticated]] function should
     * be called on the [[SecurityContextActions]] object.
     *
     * For example:
     * ```
     * LocalStorage.saveAuthCredentials(email, email);
     * LocalStorage.saveRememberMeData(email, rememberMe);
     *
     * securityHelper.onUserAuthenticated({ email: email, userId: email, rememberMe: rememberMe });
     * ```
     *
     * In the case of invalid credentials, an error should be thrown.
     *
     * @param email Email address the user entered into the UI.
     * @param password Password the user entered into the UI.
     * @param rememberMe Indicates whether the user's email should be remembered on success.
     *
     * @returns Resolve if code is credentials are valid, otherwise reject.
     */
    logIn: async (email: string, password: string, rememberMe: boolean): Promise<void> => {
        try {
            await performLogin(email, password, navigate);
            LocalStorage.saveAuthCredentials(email, email);
            LocalStorage.saveRememberMeData(email, rememberMe);
            appHelper.onUserAuthenticated({ email: email, userId: email, rememberMe: rememberMe });
        } catch (error: any) {
            console.error(`Error in login function ${error}`);
            //throw new Error('Sorry, there was a problem sending your request.');
            throw error;
        }
    },
    /**
     * The user has forgotten their password and wants help.
     * The application generally should call an API which will then send a password reset
     * link to the user's email.
     *
     * @param email Email address the user entered into the UI.
     *
     * @returns Resolve if email sending was successful, otherwise reject.
     */
    forgotPassword: async (email: string): Promise<void> => {
       try{
        await forgotPasswordApi(email)
       }
       catch (error: any) {
        console.error(`Error in forgot password function ${error}`);
        //throw new Error('Sorry, there was a problem sending your request.');
        throw error;
    }
    },
    /**
     * The user has tapped on an email with a password reset link, which they received after
     * requesting help for forgetting their password.
     * The application should take the password reset code and then verify that it is still
     * valid.
     *
     * @param code Password reset code from a reset password link.
     * @param email Email if it was passed from the reset link
     *
     * @returns Resolve if code is valid, otherwise reject.
     */
    verifyResetCode: async (code: string, email?: string): Promise<void> => {
        await sleep(500);
        if (isRandomFailure()) {
            throw new Error('Sorry, there was a problem sending your request.');
        }

        return;
    },
    /**
     * A user who has previously used "forgotPassword" now has a valid password reset code
     * and has entered a new password.
     * The application should take the user's password reset code and the newly entered
     * password and then reset the user's password.
     *
     * Note: Upon success, the user will be taken to the Login screen.
     *
     * @param code Password reset code from a link
     * @param password New Password the user entered into the UI
     * @param email Email if it was passed from the reset link
     *
     * @returns Resolve if successful, otherwise reject with an error message.
     */
    setPassword: async (code: string, password: string, email?: string): Promise<void> => {
        await sleep(500);
        if (isRandomFailure()) {
            throw new Error('Sorry, there was a problem sending your request.');
        }

        return;
    },
    /**
     * An authenticated user wants to change their password.
     * The application should try to change the user's password. Upon completion,
     * the user will be logged out of the application. Upon cancellation, the user
     * will be taken back to the application's home screen.
     *
     * @param oldPassword The user's current password as entered into the UI.
     * @param newPassword The user's new password as entered into the UI.
     *
     * @returns Resolve if successful, otherwise reject with an error message.
     */
    changePassword: async (oldPassword: string, newPassword: string): Promise<void> => {
        await sleep(1000);

        if (isRandomFailure()) {
            throw new Error('Sorry, there was a problem sending your request.');
        }

        return;
    },
});
