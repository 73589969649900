/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StarIcon from '@mui/icons-material/Star';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { InfoListItem, EmptyState, ThreeLiner } from '@brightlayer-ui/react-components';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Checkbox from '@mui/material/Checkbox';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { PanelProps, PanelArrayProps } from './panelEstateTypes';
import { PanelContext } from '../ListAndGridView/PanelsView';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { SEARCH_PARAM_PANELS, PANEL_FILTER } from '../../constants/index';
import { useNavigate } from 'react-router';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { setPanelView } from '../../redux/actions/specificPanelActions';
import PanelMenuList from '../PanelMenuButton/PanelMenuList';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/system';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Maintenance } from '@brightlayer-ui/icons-mui';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PeopleIcon from '@mui/icons-material/People';
import { Report } from '@mui/icons-material';
import { userEmailAlertsForAllPanelsToEmpty } from '../../redux/actions/panelEstateActions';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useTranslation } from 'react-i18next';
import secureLocalStorage  from  "react-secure-storage";

type Props ={
    page:number
    setPage: (page:number) => void
    path?:string
    loading?:boolean

}

export default function ListOfPanels({page,setPage,path,loading}:Props): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const panels: PanelArrayProps = React.useContext(PanelContext);
    const searchParam = secureLocalStorage.getItem(SEARCH_PARAM_PANELS);
    const filterOption = secureLocalStorage.getItem(PANEL_FILTER);
    const navigate = useNavigate();
    const dispatch = useTypedDispatch();
    const devices = panels;
    const { t } = useTranslation();
    const { installerEmailStatuses, userEmailAlerts, allPanels } = useSelector((state: any) => state.userReducer);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [panelId, setPanelId] = React.useState<number | undefined>(undefined);
    const [panelName, setPanelName] = React.useState<string>('');
    const openSort = Boolean(anchorEl);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        dispatch(setPanelView('list'));
        setPage(0)
    }, [searchParam?.toString().length]);

    const handlePanelMenuClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        panelID: number | undefined,
        panelsName: string
    ): any => {
        setAnchorEl(event.currentTarget);
        setPanelId(panelID);
        setPanelName(panelsName);
    };

    const handlePanelClick = (id: number | undefined) => {
        dispatch(userEmailAlertsForAllPanelsToEmpty())
        navigate(`/panel/${id}`);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    return (
        <div>
            {md ? (
                <Table sx={{ border: '1px solid rgba(66, 78, 84, 0.12)' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell width="50%">
                            {t('lbl_name')}&ensp;
                            </TableCell>
                            <TableCell width="47%">
                            {t('lbl_status')}&ensp;
                            </TableCell>
                            <TableCell width="3%"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {panels.length > 0 && panels !== undefined &&
                            panels?.slice((page * rowsPerPage),(page * rowsPerPage) + rowsPerPage).map((panel: PanelProps, key: number) => (
                                <TableRow key={key} sx={{ backgroundColor: '#ffff' }}>
                                    <TableCell padding="normal" align="left">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant="body2">{panel.cloudReferenceName}</Typography>
                                        </div>
                                    </TableCell>
                                    <TableCell padding='normal' align="left">
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '0.5%' }}>
                                            <Checkbox
                                                sx={{ mt: -0.9, mb: -1 }}
                                                size={'small'}
                                                icon={<CloudOffIcon color="action" />}
                                                disabled
                                                checkedIcon={<CloudDoneIcon color="success" />}
                                                checked={panel.status === 'CONNECTED'}
                                            />
                                            &ensp;
                                        </div>
                                    </TableCell>
                                    <TableCell padding="none" align="right">
                                        <div style={{ display: 'flex', flexDirection: 'row', float: 'right' }}>
                                            <IconButton onClick={() => handlePanelClick(panel.ID)}>
                                                <ChevronRightIcon color="action" />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                             <TableRow>
                                <TableCell colSpan={3}>
                                <TablePagination
                                      component="div"
                                      count={panels?.length}
                                      page={page}
                                      onPageChange={handleChangePage}
                                      rowsPerPage={rowsPerPage}
                                      rowsPerPageOptions={[10, 50, 100, 250, 500]}
                                      onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeRowsPerPage(e)}
                                      showFirstButton
                                      showLastButton
                                    />
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            ) : (
                <Table>

                    <TableBody>
                        {panels.length > 0 && panels !== undefined &&
                            panels?.slice((page * rowsPerPage),(page * rowsPerPage) + rowsPerPage).map((panel: PanelProps, key: number) => (
                                <TableRow key={key} sx={{ backgroundColor: '#ffff' }}>
                                    <TableCell width={'30%'} align="right" padding="none">
                                        <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                            hidePadding
                                            title={<Typography variant="body1">{panel.cloudReferenceName}</Typography>}
                                            subtitle={panel.panelId}
                                            icon={<Checkbox
                                                icon={<CloudOffIcon color={'action'} />}
                                                disabled
                                                checkedIcon={<CloudDoneIcon color={'success'} />}
                                                checked={panel.status === 'CONNECTED'}
                                            />}
                                            statusColor={panel.status === 'CONNECTED' ? '' : ''}
                                        />
                                    </TableCell>
                                    <TableCell width={'50%'} align="left" padding="none">
                                        <Stack direction={'row'} spacing={4}>
                                        <>
                                                
                                                {panel?.arcAccessEnabled === true ?
                                                        <Tooltip title={`${t('tooltip_ARC_Access')}`} arrow placement="top" >
                                                            <Report sx={{color:'#005EAB'}} />
                                                        </Tooltip> 
                                                        : 
                                                        <Tooltip title={`${t('tooltip_ARC_Access')}`} arrow placement="top" >
                                                            <Report color="action" />
                                                        </Tooltip>
                                                }
                                                
                                                {userEmailAlerts?.filter((user: any) => user?.device.ID === panel.ID)[0]?.siaEmailSettings?.length > 0 ?
                                                        <Tooltip title={`${t('tooltip_usrEmail_alerts')}`} arrow placement="top" >
                                                            <ContactMailIcon sx={{color:'#005EAB'}}/>
                                                        </Tooltip> 
                                                        : 
                                                        <Tooltip title={`${t('tooltip_usrEmail_alerts')}`} arrow placement="top" >
                                                            <ContactMailIcon color="action" />
                                                        </Tooltip>
                                                }
                                                
                                                {panel.userAccessEnabled ?
                                                        <Tooltip title={`${t('tooltip_usrAccess')}`} arrow placement="top" >
                                                            <PeopleIcon sx={{color:'#005EAB'}} />
                                                        </Tooltip> 
                                                        : 
                                                        <Tooltip title={`${t('tooltip_usrAccess')}`} arrow placement="top" >
                                                            <PeopleIcon color="action" />
                                                        </Tooltip>
                                                }

                                                {installerEmailStatuses?.length >= 0 && installerEmailStatuses !== undefined &&
                                                    <>
                                                    {installerEmailStatuses?.slice(-(allPanels.length)).filter((arr: any) => arr?.ID === panel?.ID)[0]?.installerEmailAlertsStatus ? 
                                                        <Tooltip title={`${t('tooltip_installerEmail_alerts')}`} arrow placement="top" >
                                                            
                                                            <NotificationsActiveIcon sx={{color:'#005EAB'}} />
                                                            
                                                            </Tooltip>
                                                         : 
                                                         <Tooltip title={`${t('tooltip_installerEmail_alerts')}`} arrow placement="top" >
                                                            
                                                            <NotificationsActiveIcon color="action" />
                                                            
                                                            </Tooltip>
                                                    }
                                                    </>
                                                }

                                                {panel?.ardEnabled === true ?
                                                        <Tooltip title={`ARD Access`} arrow placement="top" >
                                                            <Maintenance sx={{color:'#005EAB'}} />
                                                        </Tooltip> 
                                                        : 
                                                        <Tooltip title={`ARD Access`} arrow placement="top" >
                                                            <Maintenance color="action" />
                                                        </Tooltip>
                                                }
                                            </>
                                        </Stack>
                                    </TableCell>
                                    <TableCell width={'20%'} align="right" padding="none">
                                        <div style={{ display: 'flex', flexDirection: 'row', float: 'right',paddingRight:'16px' }}>
                                            {/* <Checkbox icon={<StarBorderIcon />} checkedIcon={<StarIcon />} /> */}

                                            <IconButton
                                                onClick={(event) =>
                                                    handlePanelMenuClick(event, panel.ID, panel.cloudReferenceName)
                                                }
                                            >
                                                <MoreVertIcon color="action" />
                                            </IconButton>

                                            <IconButton onClick={() => handlePanelClick(panel.ID)}>
                                                <ChevronRightIcon color="action" />
                                            </IconButton>
                                        </div>
                                        <PanelMenuList
                                            path={path}
                                            screen='overview'
                                            subEstates={undefined}
                                            estatePage={false}
                                            anchorEl={anchorEl}
                                            open={openSort}
                                            setAnchorEl={setAnchorEl}
                                            panelId={panelId}
                                            panelName={panelName}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <TablePagination
                                        component="div"
                                        count={panels?.length}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[10, 50, 100, 250, 500]}
                                        onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeRowsPerPage(e)}
                                        showFirstButton
                                        showLastButton
                                    />
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            )}

            {panels.length === 0 && filterOption !== '0' && (
                    <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
                        <div style={{ flex: '1 1 0px' }}>
                            <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                icon={<FilterListOffIcon fontSize={'inherit'} />}
                                title={`${t('msg_no_panels_found')}`}
                            />
                        </div>
                    </div>
                )
            }

                {panels.length === 0 && !loading && searchParam !== '' && searchParam !== null && filterOption === '0' && (
                <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
                    <div style={{ flex: '1 1 0px' }}>
                        <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                            icon={<ManageSearchIcon fontSize={'inherit'} />}
                            title={`${t('msg_noSearch_results')} "${searchParam}"...`}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}