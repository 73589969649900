/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { EstateProps, PanelProps, SummaryDetailsProps } from './../../components/ListAndGridView/panelEstateTypes';
import { CurrentUserProps, EstateArrayProps, PanelArrayProps } from '../../components/ListAndGridView/panelEstateTypes';
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { ActionTypes } from '../constants/panelEstateActionTypes';
import { Dispatch } from 'react';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';
import secureLocalStorage  from  "react-secure-storage";

const setCurrentUser = (currentUser: CurrentUserProps): any => {
    return {
        type: ActionTypes.SET_CURRENT_USER,
        payload: currentUser,
    };
};

const setPanels = (panels: PanelArrayProps): any => {
    return {
        type: ActionTypes.SET_PANELS,
        payload: panels,
    };
};
const setAdminPanels = (panels: PanelArrayProps): any => {
    return {
        type: ActionTypes.SET_ADMIN_PANELS,
        payload: panels,
    };
};
const setSearchedPanels = (panels: PanelArrayProps): any => {
    return {
        type: ActionTypes.SET_SEARCHED_PANELS,
        payload: panels,
    };
};

const setSummaryDetails = (summaryReport: SummaryDetailsProps): any => {
    return {
        type: ActionTypes.SET_SUMMARY_DETAILS,
        payload: summaryReport,
    };
};

const setPanelsUnFiltered = (panels: PanelArrayProps): any => {
    return {
        type: ActionTypes.SET_PANELS_UNFILTERED,
        payload: panels,
    };
};

const setEstates = (estates: EstateArrayProps): any => {
    return {
        type: ActionTypes.SET_ESTATES,
        payload: estates,
    };
};

 export const setUnassignedPanels = (unassignedPanels: PanelArrayProps): any => {
    return {
        type: ActionTypes.UNASSIGNED_PANELS,
        payload: unassignedPanels,
    };
};
const setInstallerEmailAlertsStatusForPanels = (installerEmailStatuses: any): any => {
    return {
        type: ActionTypes.SET_INSTALLER_EMAIL_ALERTS_FOR_ALL_PANELS,
        payload: installerEmailStatuses,
    };
};
const setUserEmailAlertsForAllPanels = (userEmailAlerts: any): any => {
    return {
        type: ActionTypes.SET_USER_EMAIL_ALERTS_FOR_ALL_PANELS,
        payload: userEmailAlerts,
    };
};
const setUserEmailAlertsForAllPanelsToEmpty = (userEmailAlerts: any): any => {
    return {
        type: ActionTypes.SET_USER_EMAIL_ALERTS_FOR_ALL_PANELS_TO_EMPTY,
        payload: userEmailAlerts,
    };
};

export const setAllConnectedPanels=(connectedPanels:any):any=>{
    return{
        type:ActionTypes?.SET_CONNECTED_PANEL,
        payload:connectedPanels,
    }
}

export const setAllDisconnectedPanels=(disconnectedPanels:any):any=>{
    return{
        type:ActionTypes?.SET_DISSCONNECTED_PANEL,
        payload:disconnectedPanels,
    }
}

export const setConnectedPanelsTrue=(flag:boolean):any=>{
    return{
        type:ActionTypes?.SET_CONNECTED_PANELS_TRUE,
        payload:flag
    }
}

export const setDisconnectedPanelsTrue=(flag:boolean):any=>{
    return{
        type:ActionTypes?.SET_DISCONNECTED_PANELS_TRUE,
        payload:flag
    }
}

export const setSearchedPanelsForInstaller=(panels:PanelArrayProps):any=>{
    return{
        type:ActionTypes.SET_SEARCHED_PANELS_FOR_INSTALLER,
        payload:panels
    }
}

export function fetchPanelsAndEstates() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/auth/currentuser`, {
                method: 'GET',
                credentials: 'include',
            })
                .then((res) => res.json())
                .then(async(currentUser) => {
                    secureLocalStorage.setItem('estate',JSON.stringify(currentUser));
                    dispatch(setCurrentUser(currentUser))
                    const ID = currentUser.ID;
                    const groupId = currentUser.groupId;
                    if (
                        currentUser.userType === 'ENGINEER' ||
                        currentUser.userType === 'OFFICE_STAFF' ||
                        currentUser.userType === 'MANAGER'
                    ) {
                        await fetch(
                            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/grantedby/${ID}`,
                            {
                                method: 'GET',
                                credentials: 'include',
                            }
                        )
                            .then((res) => res.json())
                            .then(async (estates) => {
                                if (
                                    estates.length !== 0 &&
                                    estates !== undefined &&
                                    estates.some((estate: EstateProps) => estate.ID === groupId)
                                ) {
                                    await fetch(
                                        `${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/${groupId}/children`,
                                        {
                                            method: 'GET',
                                            credentials: 'include',
                                        }
                                    )
                                        .then((res) => res.json())
                                        .then((estatess) => {
                                            if (estatess.length > 0) {
                                                dispatch(setEstates(estatess));
                                            }

                                        });
                                    if(currentUser.userType === 'MANAGER' ||  currentUser.userType === 'OFFICE_STAFF' || currentUser.userType === 'ENGINEER'){
                                        await fetch(`${REACT_APP_BASE_URL}/v1/panelsMiddleware/${groupId}?status=`,
                                            {
                                                method: 'GET',
                                                credentials: 'include',
                                            }
                                            )
                                            .then((res) => res.json())
                                            .then((unassignedPanels) => {
                                                 dispatch(setUnassignedPanels(unassignedPanels))
                                                 });
                                        await  fetch(
                                            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/recursiveDevice/from/${groupId}`,
                                            {
                                                method: 'GET',
                                                credentials: 'include',
                                            }
                                        )
                                            .then((res) => res.json())
                                            .then((panels) => {
                                                dispatch(setPanels(panels));
                                                dispatch(setPanelsUnFiltered(panels));
                                            });
                                        }
                                        else{
                                            dispatch(setUnassignedPanels([]))
                                            dispatch(setPanels([]))
                                        }
                                } 
                                else {
                                    if (estates.length > 0) {
                                        dispatch(setEstates(estates));
                                        dispatch(setUnassignedPanels([]))
                                        dispatch(setPanels([]))
                                    } else {
                                        dispatch(setEstates(estates));
                                        dispatch(setUnassignedPanels([]))
                                        dispatch(setPanels([]))
                                    }
                                }
                            });
                    } else if (currentUser.userType === 'INSTALLER') 
                    {
                         fetch(currentUser.userType === 'ADMIN' ? `${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/`:
                         `${REACT_APP_BASE_URL}/v1/panelsMiddleware/${groupId}?status=`,
                            {
                                method: 'GET',
                                credentials: 'include',
                            }
                        )
                            .then((res) => res.json())
                            .then((unassignedPanels) => {
                                dispatch(setUnassignedPanels(unassignedPanels))
                                
                            });
                         fetch(
                            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/recursiveDevice/from/${groupId}`,
                            {
                                method: 'GET',
                                credentials: 'include',
                            }
                        )
                            .then((res) => res.json())
                            .then((panels) => {
                                dispatch(setPanels(panels));
                                dispatch(setPanelsUnFiltered(panels));
                            });
                            
                         fetch(currentUser.userType === 'ADMIN'? 
                         `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/installersWithGroup?start=0&limit=20` 
                            :
                            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/${groupId}/children`,
                            {
                                method: 'GET',
                                credentials: 'include',
                            }
                        )
                            .then((res) => res.json())
                            .then((estates) => {
                                if(currentUser.userType === 'ADMIN')
                                {
                                dispatch(setEstates(
                                    estates.map((estate:any) => ({
                                        ID:estate.groupId,
                                        name:`${estate.firstName} ${estate.lastName}`,
                                        childPresented:estate.childPresented,
                                        devicesAssigned:estate.devicesAssigned,
                                        lastAlert:estate.lastAlert,
                                    }))
                                    ));  
                                }
                                else{
                                dispatch(setEstates(estates));
                                }
                            });

                        // await fetch(
                        //     `${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/devices/${groupId}?status=`,
                        //     {
                        //         method: 'GET',
                        //         credentials: 'include',
                        //     }
                        // )
                        //     .then((res) => res.json())
                        //     .then((unassignedPanels) => dispatch(setUnassignedPanels(unassignedPanels)));
                    } 

                    else if(currentUser.userType === 'MOBILE_APPLICATION_USER') {
                        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/`,
                            {
                                method: 'GET',
                                credentials: 'include',
                            }
                        )
                            .then((res) => res.json())
                            .then((panels) => {
                                dispatch(setPanels(panels));
                                dispatch(setUnassignedPanels(panels));
                            })
                    }
                })
    };
}
export function userEmailAlertsForAllPanelsToEmpty() {
    return function (dispatch: Dispatch<DispatchTypesProps>): any {
        dispatch(setUserEmailAlertsForAllPanelsToEmpty([]));
    };
}

export function fetchAdminPanels(start: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/auth/currentuser`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async (data) => {
                if (data !== undefined) {
                    dispatch(setCurrentUser(data));
                }
                // await fetch(
                //     `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/installersWithGroup`,
                //     {
                //         method: 'GET',
                //         credentials: 'include',
                //     }
                // )
                //     .then((res) => res.json())
                //     .then((allInstallers) => 
                //     dispatch(setAllInstallers(allInstallers)
                //     ))

                    await fetch(
                        `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/installersWithGroup?start=${start}&limit=1000`,
                        {
                            method: 'GET',
                            credentials: 'include',
                        }
                    )
                        .then((res) => res.json())
                        .then((installers) => {
                            const list: any[] = [];
                            dispatch(setEstates(installers.map((estate:any) => ({
                                ID:estate.groupId,
                                name:`${estate.firstName} ${estate.lastName}`,
                                childPresented:estate.childPresented,
                                devicesAssigned:estate.devicesAssigned,
                                lastAlert:estate.lastAlert,
                            }))));
                            installers.map(async (installer: any, index: number) => {
                                await fetch(
                                    `${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/recursiveDevice/from/${installer.groupId}`,
                                    {
                                        method: 'GET',
                                        credentials: 'include',
                                    }
                                )
                                    .then((res) => res.json())
                                    .then((panels) => {
                                        if (panels.length !== 0) {
                                            panels.forEach((panel: any) => {
                                                list.push(panel);
                                            });
                                        }
            
                                        if (index === installers.length - 1) {
                                            dispatch(setAdminPanels(list));
                                            dispatch(setPanelsUnFiltered(list));
                                        }
                                    });
                            });
                        });
                })
                await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/`, {
                    method: 'GET',
                    credentials: 'include',
                })
                    .then((res) => res.json())
                    .then((unassignedPanels) => {
                        dispatch(setUnassignedPanels(unassignedPanels));
                    });
    };
}


export function fetchAdminSearchedPanels(search: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/find?all=true&limit=500&search=${encodeURIComponent(search)}&start=0`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => {
                dispatch(setSearchedPanels(data))
                // if (data.length !== 0 || data !== undefined) {
                //     data.map(async (panel: PanelProps) => {
                //         await fetch(
                //             `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/alertevents/${panel.panelId}`,
                //             {
                //                 method: 'GET',
                //                 credentials: 'include',
                //                 headers: {
                //                     'Content-Type': 'application/json',
                //                 },
                //             }
                //         ).then((res) => {
                //             if (res.status !== 204) {
                //                 dispatch(
                //                     setInstallerEmailAlertsStatusForPanels({
                //                         ID: panel.ID,
                //                         name: panel.cloudReferenceName,
                //                         installerEmailAlertsStatus: true,
                //                     })
                //                 );
                //             } else {
                //                 dispatch(
                //                     setInstallerEmailAlertsStatusForPanels({
                //                         ID: panel.ID,
                //                         name: panel.cloudReferenceName,
                //                         installerEmailAlertsStatus: false,
                //                     })
                //                 );
                //             }
                //         });
                //         await fetch(
                //             `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/config/siaemailoption/${panel.panelId}`,
                //             {
                //                 method: 'GET',
                //                 credentials: 'include',
                //             }
                //         )
                //             .then((res) => res.json())
                //             .then((userEmail) => dispatch(setUserEmailAlertsForAllPanels(userEmail)));
                //     });
                // }
            }
            )
    };
}


export function fetchPanelsAndEstatesOfInstaller(groupId: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/v1/panelsMiddleware/${groupId}?status=`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((unassignedPanels) => {
                dispatch(setUnassignedPanels(unassignedPanels));
                // if (unassignedPanels.length !== 0 || unassignedPanels !== undefined) {
                //     unassignedPanels.map(async (panel: PanelProps) => {
                //         await fetch(
                //             `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/alertevents/${panel.panelId}`,
                //             {
                //                 method: 'GET',
                //                 credentials: 'include',
                //                 headers: {
                //                     'Content-Type': 'application/json',
                //                 },
                //             }
                //         ).then((res) => {
                //             if (res.status !== 204) {
                //                 dispatch(
                //                     setInstallerEmailAlertsStatusForPanels({
                //                         ID: panel.ID,
                //                         name: panel.cloudReferenceName,
                //                         installerEmailAlertsStatus: true,
                //                     })
                //                 );
                //             } else {
                //                 dispatch(
                //                     setInstallerEmailAlertsStatusForPanels({
                //                         ID: panel.ID,
                //                         name: panel.cloudReferenceName,
                //                         installerEmailAlertsStatus: false,
                //                     })
                //                 );
                //             }
                //         });
                //         await fetch(
                //             `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/config/siaemailoption/${panel.panelId}`,
                //             {
                //                 method: 'GET',
                //                 credentials: 'include',
                //             }
                //         )
                //             .then((res) => res.json())
                //             .then((userEmail) => dispatch(setUserEmailAlertsForAllPanels(userEmail)));
                //     });
                // }
            });
        // await fetch(
        //     `${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/recursiveDevice/from/${groupId}`,
        //     {
        //         method: 'GET',
        //         credentials: 'include',
        //     }
        // )
        //     .then((res) => res.json())
        //     .then((panels) => {
        //         dispatch(setPanels(panels));
        //         dispatch(setPanelsUnFiltered(panels));
        //     });

        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/${groupId}/children`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((estates) => dispatch(setEstates(estates)));
    };
}

export function fetchSummaryDetails() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/reports/summaryReport`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => dispatch(setSummaryDetails(data)) )
    };
}

export function fetchPanelsAlertsAndInstallerEmailOption(unassignedPanels:any) {
    return function (dispatch: Dispatch<DispatchTypesProps>): any {
        if (unassignedPanels.length !== 0 || unassignedPanels !== undefined) {
                    unassignedPanels.map(async (panel: PanelProps) => {
                        await fetch(
                            `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/alertevents/${panel.panelId}`,
                            {
                                method: 'GET',
                                credentials: 'include',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            }
                        ).then((res) => {
                            if (res.status !== 204) {
                                dispatch(
                                    setInstallerEmailAlertsStatusForPanels({
                                        ID: panel.ID,
                                        name: panel.cloudReferenceName,
                                        installerEmailAlertsStatus: true,
                                    })
                                );
                            } else {
                                dispatch(
                                    setInstallerEmailAlertsStatusForPanels({
                                        ID: panel.ID,
                                        name: panel.cloudReferenceName,
                                        installerEmailAlertsStatus: false,
                                    })
                                );
                            }
                        });
                        await fetch(
                            `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/config/siaemailoption/${panel.panelId}`,
                            {
                                method: 'GET',
                                credentials: 'include',
                            }
                        )
                            .then((res) => res.json())
                            .then((userEmail) => dispatch(setUserEmailAlertsForAllPanels(userEmail)));
                    });
                }
    };
}


export function getSearchedPanelsForInstaller(search:string){
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/find?limit=0&search=${search}&start=0`,{
            method: 'GET',
            credentials: 'include',
        })
        .then((res)=>res.json())
        .then((panels)=>dispatch(setSearchedPanelsForInstaller(panels)))
    }
}



   