/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, Fab, IconButton, MobileStepper, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { Spacer } from '@brightlayer-ui/react-components';
import { Document, Page, pdfjs } from 'react-pdf';
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import '@react-pdf-viewer/core/lib/styles/index.css';
import { PanelProps } from '../ListAndGridView/panelEstateTypes';
import { useTranslation } from 'react-i18next';
import { REACT_ARD_BASE_URL } from '../../entities/Endpoints';
const pdfWorkerUrl = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const pdfWorkerUrl = new URL(
//     'pdfjs-dist/legacy/build/pdf.worker.min.js',
//     import.meta.url,
//   ).toString();


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

type ReportDetailsProps = {
    page: string
    specificPanel?: PanelProps
    report: any
    open: boolean
    setOpen: (open: boolean) => void
}

export default function PdfReportModal({ page, specificPanel, report, open, setOpen }: ReportDetailsProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDownloadPdf = async() => {
        await fetch(`${REACT_ARD_BASE_URL}/panels/ard/reportpdf/${specificPanel?.panelId}/${report.id}`,{
         method: 'GET',
         headers: {
             'Content-Type': 'application/json',
         },
         credentials: 'include'})
        .then((response) => response.blob())
            .then((blob) => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                const alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = page === 'remote-servicing-tab' ? `${specificPanel?.panelId}-${report.id}.pdf` :'SamplePDF.pdf';
                alink.click();
            })
        
    }

    return (
        <div>
            <>
                <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100%',
                            height: '100%',
                            backgroundColor: md ? 'background.paper' : '#F3F3F3',
                            boxShadow: 20,
                            p: 0,
                        }}
                    >
                        <Paper elevation={md ? 0 : 2} sx={{ pb: 0 }}>
                            <Paper
                                elevation={4}
                                sx={{
                                    paddingTop: 2,
                                    paddingBottom: 1,
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                                &ensp;&ensp;
                                {md ? (
                                    <>
                                        <Typography sx={{ pt: 0.5 }} variant="h6">
                                            {t('lbl_reportDetails')} - {report?.id}
                                        </Typography>
                                        <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                        <IconButton onClick={handleDownloadPdf}>
                                            <DownloadIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant="h6">{t('lbl_service_report')}</Typography>
                                            <Typography variant="body1">{report?.id}</Typography>
                                        </div>
                                    </>
                                )}
                            </Paper>
                            <Divider />
                            <Paper sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#F3F3F3' }}>
                                <Worker workerUrl={'/pdf.worker.min.js'}>
                                    <div
                                        style={{
                                            height: '100vh',
                                            width: md ? '100vw' : '80vw',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <Viewer
                                            defaultScale={md ? SpecialZoomLevel.PageFit : 1.7}
                                            fileUrl={page === 'remote-servicing-tab' ? `${REACT_ARD_BASE_URL}/panels/ard/reportpdf/${specificPanel?.panelId}/${report.id}` : `${REACT_ARD_BASE_URL}/panels/ard/reportpdf/7TeTuDpL2SP6pC2sTcrpJg`}
                                            withCredentials={true}
                                        />
                                    </div>
                                </Worker>
                            </Paper>
                        </Paper>
                        {md ? (
                            <></>
                        ) : (
                            <>
                            
                                <Fab onClick={handleDownloadPdf} color="primary" sx={{ position: 'fixed', top: '90%', left: '90%' }}>
                                        <DownloadIcon sx={{ position: 'absolute' }} />
                                </Fab>
                                
                            </>
                        )}
                    </Box>
                </Modal>
            </>
        </div>
    );
}
