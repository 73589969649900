/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
import * as React from 'react';
import { makeStyles, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, IconButton, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { InfoListItem, ThreeLiner } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useParams } from 'react-router-dom';
import { fetchPanelLogs, fetchPanelLogsInitial, fetchPanelLogsInitialLength } from '../../redux/actions/specificPanelActions';
import TablePagination from '@mui/material/TablePagination';
import Fab from '@mui/material/Fab';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from 'react-csv';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/Info';
import { PanelLogProps } from './PanelLogsTypes';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';
import Loader from '../CircularLoader/Loader';
import secureLocalStorage from 'react-secure-storage';
import { getAMorPM, getDate, getDateFormat, getHours, getMinutes, getSeconds, getMonth, getTimeFormat, getYear } from '../Timestamp/timestampCal';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),

    color: theme.palette.text.secondary,
}));

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));


export default function PanelLogs(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const role = secureLocalStorage.getItem('role')
    let { panelLogs } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { currentUser } = useSelector((state: any) => state.userReducer);
    //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
    const { specificPanel, logsLength } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    let { panelLogsData } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const [page, setPage] = React.useState(0);
    const [query, setQuery] = React.useState('')
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [lengthFetch, setLengthFetch] = React.useState(true);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const dispatch = useTypedDispatch();
    const { id } = useParams();
    const { t } = useTranslation();

    React.useEffect(() => {
        // if(page === 0){
        const logsFetch = async () => {
            if (specificPanel?.status === 'DISCONNECTED') {
                setDataLoaded(true);
                await dispatch(fetchPanelLogsInitial(specificPanel.panelId))
                panelLogsData = []
                panelLogs = []
            }
            else {
                setDataLoaded(false);
                await dispatch(fetchPanelLogsInitial(specificPanel.panelId))
                setDataLoaded(true);
            }

        }

        logsFetch()
        // }
        // else
        // dispatch(fetchPanelLogs(panelLogs[9]?.id,specificPanel.panelId))

        if (lengthFetch) {
            const fetching = async () => {
                if (specificPanel?.status === 'DISCONNECTED') {
                    setDataLoaded(true);
                    await dispatch(fetchPanelLogsInitialLength(specificPanel.panelId))
                    panelLogsData = []
                    panelLogs = []
                }
                else {
                    setDataLoaded(false);
                    await dispatch(fetchPanelLogsInitialLength(specificPanel.panelId))
                    setDataLoaded(true);
                }

            }
            fetching()
            setLengthFetch(false)
        }
    }, []);

    const handleCancelClick = (): any => {
        setQuery('');
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const Headers = [
        { label: 'ID', key: 'id' },
        { label: 'Type', key: 'type' },
        { label: 'Description', key: 'desc' },
        { label: 'Verbose', key: 'verbose' },
        { label: 'Event timestamp', key: 'timeStamp' },
        { label: 'Event user', key: 'user' },
        { label: 'Event username', key: 'userName' },
    ];

    const csvReport = {
        filename: `panel-${id}-logs.csv`,
        headers: Headers,
        data: panelLogsData,
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    try {
        if (panelLogs?.length > 0) {
            const filteredPanels = panelLogs.filter(
                (log: PanelLogProps) => (
                    log.desc.toLowerCase()
                        .includes(query.toLowerCase()) || log.events[0]?.username?.toLowerCase()
                            .includes(query.toLowerCase()) || log.events[0]?.user?.toLowerCase()
                                .includes(query.toLowerCase()) || getDateFormat(Number(log?.events[0].timestamp) * 1000).toString().toLowerCase()
                                    .includes(query.toLowerCase()) || getTimeFormat(Number(log?.events[0].timestamp) * 1000).toString().toLowerCase()
                                        .includes(query.toLowerCase()) || log.verbose && log.verbose.toLowerCase().includes(query.toLowerCase())
                )
            );
            panelLogs = query.length > 0 ? filteredPanels : panelLogs;
        }
    } catch (exception) {
        console.log(`Exception in panel notification filtering: ${JSON.stringify(exception)}`);
    }

    return (
        <div style={md ? {} : { maxHeight: '100vh', overflow: 'auto' }}>
            {!dataLoaded && <Loader />}
            <Box
                sx={
                    md
                        ? {
                            flexGrow: 1,
                            marginLeft: -2,
                            marginRight: -2,
                            marginTop: -2,
                            pt: 2,
                            maxHeight: '90vh',
                            overflow: 'auto',
                            pb: 40,
                        }
                        : {
                            flexGrow: 1,
                            marginLeft: 8,
                            marginRight: 8,
                            marginTop: 4,
                            pb: 40,
                        }
                }
            >
                <Paper elevation={1} square>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Item sx={{ height: md ? '100vh' : '' }} elevation={1}>
                                <Box sx={{ flexGrow: 1, marginLeft: 2, marginRight: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={md ? 10.5 : 12}>
                                            <Item elevation={0}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder={t('lbl_search')}
                                                    onChange={(e) => {
                                                        setPage(0)
                                                        setQuery(e.target.value)
                                                    }}
                                                    value={query}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {query.length !== 0 && (
                                                                    <IconButton
                                                                        onClick={(event) => handleCancelClick()}
                                                                    >
                                                                        <CancelIcon />
                                                                    </IconButton>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Item>
                                        </Grid>
                                        {md && (
                                            <Grid item xs={1.5}>
                                                {(currentUser?.userType === 'INSTALLER' ||
                                                    currentUser?.userType === 'MANAGER' ||
                                                    currentUser?.userType === 'ENGINEER' ||
                                                    role === 'ADMIN') && (
                                                        <Item elevation={0}>
                                                            {(panelLogs?.length > 0 && panelLogsData.length > 0) ?
                                                                <CSVLink {...csvReport}>
                                                                    <IconButton size="small">
                                                                        <DownloadIcon style={{ marginTop: 15 }} />
                                                                    </IconButton>
                                                                </CSVLink>
                                                                :
                                                                <IconButton disabled size="small">
                                                                    <DownloadIcon style={{ marginTop: 15 }} />
                                                                </IconButton>
                                                            }
                                                        </Item>
                                                    )}
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Item elevation={0}></Item>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{ marginLeft: md ? -2 : 0, marginRight: md ? -2 : 0 }}
                                                elevation={md ? 0 : 1}
                                            >
                                                {panelLogs?.slice((page * rowsPerPage), (page * rowsPerPage) + rowsPerPage).map((panelLog: PanelLogProps, key: number) => (
                                                    <div key={key}>
                                                        {md ? (
                                                            <>
                                                                <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                    title={undefined}
                                                                    subtitle={undefined}
                                                                    info={undefined}
                                                                    backgroundColor='#FBFBFB'
                                                                    iconAlign="left"
                                                                    divider="full"
                                                                    leftComponent={
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    <strong>
                                                                                        {getHours(
                                                                                            Number(
                                                                                                panelLog?.events[0]
                                                                                                    .timestamp
                                                                                            ) * 1000
                                                                                        )}
                                                                                        :
                                                                                        {getMinutes(
                                                                                            Number(
                                                                                                panelLog?.events[0]
                                                                                                    .timestamp
                                                                                            ) * 1000
                                                                                        )}
                                                                                        :
                                                                                        {getSeconds(
                                                                                            Number(
                                                                                                panelLog?.events[0]
                                                                                                    .timestamp
                                                                                            ) * 1000
                                                                                        )}
                                                                                    </strong>
                                                                                    &ensp;

                                                                                </div>
                                                                                <div>
                                                                                    {getDate(
                                                                                        Number(
                                                                                            panelLog?.events[0]
                                                                                                .timestamp
                                                                                        ) * 1000
                                                                                    )}
                                                                                    /
                                                                                    {getMonth(
                                                                                        Number(
                                                                                            panelLog?.events[0]
                                                                                                .timestamp
                                                                                        ) * 1000
                                                                                    )}
                                                                                    /
                                                                                    {getYear(
                                                                                        Number(
                                                                                            panelLog?.events[0]
                                                                                                .timestamp
                                                                                        ) * 1000
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            &ensp;&ensp;&ensp;&ensp;
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                }}
                                                                            >
                                                                                <Typography
                                                                                    noWrap
                                                                                    sx={{
                                                                                        maxWidth: 100,
                                                                                        display: 'inline',
                                                                                        overflow: 'auto',
                                                                                    }}
                                                                                    variant="body1"
                                                                                >
                                                                                    {panelLog?.events[0].user?.length >
                                                                                        0
                                                                                        ? `User: ${panelLog?.events[0].user}`
                                                                                        : `-`}
                                                                                </Typography>
                                                                                <Typography variant="body2">
                                                                                    {panelLog?.desc}
                                                                                </Typography>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    icon={<InfoIcon color="primary" />}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                    title={undefined}
                                                                    subtitle={undefined}
                                                                    info={undefined}
                                                                    backgroundColor='#FBFBFB'
                                                                    iconAlign="left"
                                                                    divider="full"
                                                                    leftComponent={
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    <strong>
                                                                                        {getHours(
                                                                                            Number(
                                                                                                panelLog?.events[0]
                                                                                                    .timestamp
                                                                                            ) * 1000
                                                                                        )}
                                                                                        :
                                                                                        {getMinutes(
                                                                                            Number(
                                                                                                panelLog?.events[0]
                                                                                                    .timestamp
                                                                                            ) * 1000
                                                                                        )}
                                                                                        :
                                                                                        {getSeconds(
                                                                                            Number(
                                                                                                panelLog?.events[0]
                                                                                                    .timestamp
                                                                                            ) * 1000
                                                                                        )}
                                                                                    </strong>
                                                                                    &ensp;
                                                                                </div>
                                                                                <div>
                                                                                    {getDate(
                                                                                        Number(
                                                                                            panelLog?.events[0]
                                                                                                .timestamp
                                                                                        ) * 1000
                                                                                    )}
                                                                                    /
                                                                                    {getMonth(
                                                                                        Number(
                                                                                            panelLog?.events[0]
                                                                                                .timestamp
                                                                                        ) * 1000
                                                                                    )}
                                                                                    /
                                                                                    {getYear(
                                                                                        Number(
                                                                                            panelLog?.events[0]
                                                                                                .timestamp
                                                                                        ) * 1000
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            &ensp;&ensp;&ensp;&ensp;&ensp;
                                                                            <div>
                                                                                <ThreeLiner nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                                    subtitle={
                                                                                        <input
                                                                                            readOnly
                                                                                            value={panelLog?.desc}
                                                                                            style={{
                                                                                                border: 'none',
                                                                                                outline: 'none',
                                                                                                fontSize: 16,
                                                                                                fontFamily: 'Open Sans',
                                                                                                fontStyle: 'normal',
                                                                                                fontWeight: 600,
                                                                                                lineHeight: 1,
                                                                                                backgroundColor: '#FBFBFB'
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    info={<input
                                                                                        readOnly
                                                                                        value={panelLog?.verbose ? panelLog?.verbose : '-'}
                                                                                        style={{
                                                                                            border: 'none',
                                                                                            outline: 'none',
                                                                                            fontSize: 16,
                                                                                            fontFamily: 'Open Sans',
                                                                                            fontStyle: 'normal',
                                                                                            fontWeight: 400,
                                                                                            lineHeight: 1,
                                                                                            backgroundColor: '#FBFBFB'
                                                                                        }}
                                                                                    />}
                                                                                />
                                                                            </div>
                                                                            &ensp;&ensp;
                                                                            <div>
                                                                                <ThreeLiner nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                                    subtitle={
                                                                                        <div
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'column',
                                                                                            }}
                                                                                        >
                                                                                            {(panelLog?.events[0]
                                                                                                .user === undefined &&
                                                                                                panelLog?.events[0]
                                                                                                    .username ===
                                                                                                undefined) ||
                                                                                                (panelLog?.events[0]
                                                                                                    .user === '' &&
                                                                                                    panelLog?.events[0]
                                                                                                        .username ===
                                                                                                    '') ? (
                                                                                                <>{'-'}</>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <Typography variant="body1">
                                                                                                        {panelLog
                                                                                                            ?.events[0]
                                                                                                            .user
                                                                                                            ?.length > 0
                                                                                                            ? `User: ${panelLog?.events[0].user}`
                                                                                                            : ``}
                                                                                                    </Typography>
                                                                                                    <Typography variant="body2">
                                                                                                        {panelLog
                                                                                                            ?.events[0]
                                                                                                            .username
                                                                                                            ?.length > 0
                                                                                                            ? `Username: ${panelLog?.events[0].username}`
                                                                                                            : ``}
                                                                                                    </Typography>
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    }
                                                                                    info=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    icon={<InfoIcon color="primary" />}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                ))}
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12}></Grid>
                                        {!md &&
                                            <Grid item xs={6}>
                                                <Item elevation={0}>
                                                    {(panelLogs.length > 0 && panelLogsData.length > 0) ?
                                                        <CSVLink {...csvReport}>
                                                            {(role === 'INSTALLER' ||
                                                                role === 'MANAGER' ||
                                                                role === 'ENGINEER' ||
                                                                role === 'ADMIN') && (
                                                                    <Button variant='contained' disabled={panelLogs.length === 0} startIcon={<DownloadIcon />}>
                                                                        {t('lbl_dwnPanelLog')}
                                                                    </Button>
                                                                )}
                                                        </CSVLink> :
                                                        <Button variant='contained' disabled={panelLogs.length === 0} startIcon={<DownloadIcon />}>
                                                            {t('lbl_dwnPanelLog')}
                                                        </Button>
                                                    }
                                                </Item>
                                            </Grid>}
                                        <Grid item xs={md ? 12 : 6}>
                                            <Item
                                                sx={
                                                    md
                                                        ? {
                                                            position: 'fixed',
                                                            top: '100%',
                                                            transform: 'translate(-4%,-100%)',
                                                            width: '100%',
                                                        }
                                                        : {}
                                                }
                                                elevation={0}
                                            >
                                                <TablePagination
                                                    component="div"
                                                    count={panelLogs?.length}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    rowsPerPage={rowsPerPage}
                                                    onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeRowsPerPage(e)}
                                                    showFirstButton
                                                    showLastButton
                                                />
                                            </Item>
                                        </Grid>
                                        {md && <Grid item xs={12}></Grid>}
                                        {md && <Grid item xs={12}></Grid>}
                                        {md && <Grid item xs={12}></Grid>}
                                    </Grid>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    );
}